body .container-fluid input[type=checkbox],
body .container-fluid input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: 2px solid #e4e4e4;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  overflow: hidden;
  cursor: pointer;
}

body .container-fluid input[type=checkbox]::before,
body .container-fluid input[type=radio]::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  -webkit-clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  transform: scale(0);
  transform-origin: bottom left;
  overflow: hidden;
}

body .container-fluid input[type=checkbox]:checked::before,
body .container-fluid input[type=radio]:checked::before {
  transform: scale(1);
}

body .container-fluid input[type=checkbox]:checked,
body .container-fluid input[type=radio]:checked {
  background-color: #43a5f5;
  border: 2px solid #43a5f5;
}

body .container-fluid {
  display: flex;
  align-items: center;
  justify-content: center;
}

body .container-fluid .wx-search-container-section {
  width: 875px;
  padding: 20px;
  border-radius: 16px;
}

body .container-fluid .wx-search-container-section .wx-search-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row {
  display: flex;
  flex-direction: row;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search {
  width: 396px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 350px;
  position: relative;

}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input {
  width: 375px;
  height: 50px;
  display: flex;
  align-items: center;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input {
  background-color: transparent;
  width: 345px;
  border: none;
  outline: none;
  margin-right: 5px;
  padding: 20px 0 20px 0;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input::-moz-placeholder {
  font-size: 15px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .wx-modal-input::placeholder {
  font-size: 15px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-input .bi {
  color: #727272;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section {
  width: 396px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  top: 55px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul {
  width: 371px;
  margin: 0;
  padding: 0;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li {
  width: 396px;
  border-bottom: 1px solid #86acc654;
  padding: 12px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box {
  height: 30px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box .wx-location-name {
  font-size: 15px;
  line-height: 2px;
  color: #2f1f19;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .wx-location-name-box .wx-location-title {
  font-size: 13px;
  color: #7b7a7a;
  line-height: 0px;
  letter-spacing: 0.4px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row #wx-location-search .wx-search-section .wx-search-result-section ul li .bi-check {
  color: #43a5f5;
  margin-right: 15px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search {
  width: 190px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  line-height: 30px;
  color: #2f1f19;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 15px;
  cursor: pointer;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search:last-child {
  margin-right: 0px !important;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search input[type=text] {
  border: none;
  outline: none;
  width: 140px;
  height: 25px;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-showplace {
  width: 140px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list {
  width: 270px;
  position: absolute;
  top: 55px;
  left: 0px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  z-index: 999;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wx-elan-number, .adsnumber{
  background: white !important;
}
.h-38{
  height: 38px;
}
.h-38 span{
  padding: 6px 10px !important;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #86acc654;
  font-size: 15px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list-style {
  width: 190px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-list-style li {
  width: 190px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .icon-rotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
  color: #43a5f5;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .icon {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-room-list .wx-checkbox-label {
  width: 179px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  line-height: 25px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-room-list li:first-child {
  border-bottom: 1px solid #eceae8;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field,
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price {
  padding: 0px 10px 10px 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price .wx-price-input,body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field .wx-field-input{
  border:1px solid #86acc654;
  border-radius: 8px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price .wx-price-input input[type=text],body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field .wx-field-input input[type=text] {
  width: 100%;
  padding-left:10px;
  border-radius: 8px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-price  span, .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-field span{
  font-size: 13px;
  color: #8a8a8a;
  letter-spacing:1px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons {
  width: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 10px;
  margin: 0;
  height: 50px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .button-section {
  padding: 0px !important;
  height: 50px;
}


body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons svg {
  color: #535353;
  cursor: pointer;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons #wx-buton {
  border: none;
  background-color: #fa742d;
  overflow: hidden;
  width: 115px;
  border-radius: 6px;
  height: 38px;
  line-height: 35px;
}

body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-more-icons #wx-buton .wx-btn {
  border: none;
  outline: none;
  color: #fff;
  width: 115px;
  font-size:15px;
  padding: 18px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search {
  height: auto;
  min-width: 805px;
  width: auto;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #dfe3e9;
  border-radius:6px;
  padding: 15px 12px;
  z-index: 999;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan {
  display: flex;
  justify-content: space-between;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group {
  display: flex;
  justify-content: space-between;
  color: #8a8a8a;
  margin-right: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group {
  height: 38px;
  display: flex;
  border: 1px solid #86acc654;
  border-radius: 8px;
  overflow: hidden;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label {
  border-right: 1px solid #86acc654;
  background: #fafaf9;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label input {
  display: none;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label input:checked~span {
  background-color: #43a5f5;
  color: #fff;                                                                                                        
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label input:checked .new-checkbox_btn-label {
  background-color: #43a5f5;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label .wx-radio-btn {
  height: 38px;
  background-color: #fafaf9;
  font-size: 15px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-detailed-search .wx-search-elan .wx-search-group .wx-search-radio-group .new-checkbox_btn-label:last-child {
  border-right: 0px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area {
  display: flex;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-search-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs {
  width: 180px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #86acc654;
  background-color: #fafaf9;
  border-radius: 8px;
  padding: 4px;
  margin-right: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs .wx-range-input {
  width: calc(50% - 1px);
  box-sizing: border-box;
  height: 27px;
  background-color: transparent;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-area .wx-range-inputs .wx-range-input:first-of-type {
  border-right: 1px solid #86acc654;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number {
  border: 1px solid #86acc6c9;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: #fafaf9;
  border-radius: 8px;
  width: 160px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number input {
  padding-left: 5px;
  background-color: #fafaf9;
  width: 150px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-elan-number input ::placeholder {
  font-size: 15px;
  color: #727272;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-search-label span {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.6px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  background-color: #fafaf9;
  font-size: 14px;
  line-height: 13px;
  color: #4a4a4a;
  margin-right: 15px;
  cursor: pointer;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label input[type=checkbox] {
  height: 15px;
  width: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations-buttons .wx-search-regular .wx-checkbox_label .wx-filter-checkbox {
  padding: 9px;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor {
  display: flex;
  margin-bottom: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-floor-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs {
  width: 180px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #86acc654;
  background-color: #fafaf9;
  border-radius: 8px;
  padding: 4px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs .wx-range-input {
  width: calc(50% - 1px);
  height: 27px;
  box-sizing: border-box;
  background-color: transparent;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-range-inputs .wx-range-input:first-of-type {
  border-right: 1px solid #86acc654;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor {
  display: flex;
  align-items: flex-end;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label {
  margin-left: 15px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  line-height: 14px;
  color: #4a4a4a;
  box-sizing: border-box;
  background-color: #fafaf9;
  font-size: 14px;
  cursor: pointer;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox],
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: 2px solid #e4e4e4;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
  overflow: hidden;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]::before
{
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  -webkit-clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
  transform: scale(0);
  transform-origin: bottom left;
  overflow: hidden;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked::before,
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked::before {
  transform: scale(1);
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=checkbox]:checked,
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label input[type=radio]:checked {
  background-color: #43a5f5;
  border: 2px solid #43a5f5;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-floor .wx-checkbox-floor .wx-checkbox_label .wx-filter-checkbox {
  padding: 8px;
  font-size: 15px;
  
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-building-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
  margin-bottom: 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building {
  display: flex;
  align-items: flex-end;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building .wx-checkbox_label {
  margin-right: 8px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #86acc654;
  border-radius: 8px;
  background-color: #fafaf9;
  font-size: 15px;
  line-height: 14px;
  color: #4a4a4a;
  cursor: pointer;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-building-type .wx-checkbox-building .wx-checkbox_label .wx-filter-checkbox {
  padding: 8px;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations .wx-search-label span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #8a8a8a;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search .wx-search-locations .wx-search-locations-buttons .modal-btn {
  width: 180px;
  border: 1px solid #86acc654;
  margin-right: 10px;
  color: #4a4a4a;
  background-color: #fafaf9;
  font-size: 15px;
  border-radius: 8px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail {
  width: 100%;
}
.new-checkbox_btn-label{
  background: #fafaf9;
  display: flex;
  overflow: hidden;
}
.new-checkbox_btn-label input[type=checkbox]{
  display: none !important;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label {
  background: #fafaf9;
  border: 1px solid #86acc654;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label input {
  display: none;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label span {
  color: #4a4a4a;
  background-color: #fafaf9;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0px 10px 0 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search .wx-checkbox_btn-label:first-child {
  margin-left: 0;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search input:checked~span {
  background-color: #43a5f5;
  color: #fff;
  padding: 0 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-more-detail_search input:checked .wx-checkbox_btn-label {
  background-color: #43a5f5;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot {
  padding: 15px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #86acc654;
  margin-top: 10px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn {
  cursor: pointer;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn span,
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .hiddenn .bi {
  color: #43a5f5;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons {
  display: flex;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btnClearAll {
  border: 1px solid #86acc654;
  border-radius: 8px;
  padding: 2px 20px;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btnClearAll input {
  border: none;
  background-color: transparent;
  color: #4a4a4a;
  font-size: 15px;
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btn-show {
  background-color: #43a5f5;
  
  margin-left: 10px;
  border-radius: 8px;
  
}
body .container-fluid .wx-search-container-section .wx-search-container .wx-search-row .wx-search-foot .wx-buttons .wx-btn-show span{
  font-size: 15px;
  color:#fff;
}
body .container-fluid .hide {
  display: none;
}
body .container-fluid .wx-addAllChecked ul {
  width: 690px;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding-left: 3px;
}
body .container-fluid .wx-addAllChecked ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  list-style: none;
  padding: 3px 10px;
  background-color: #fff;
  border-radius: 8px;
  margin-right: 8px;
  margin-top: 15px;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  color: #6191b4;
  background: #f0f4f9;
  border-radius: 0.3rem;
  display: inline-flex;
}
body .container-fluid .wx-addAllChecked ul li .wx-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  margin-top: 5px;
}
body .container-fluid .wx-addAllChecked ul li .wx-box span {
  font-size: 13px;
  list-style: none;
  padding-bottom: 4px;
  line-height: 0px;
}
body .container-fluid .wx-addAllChecked ul li .wx-box .wx-title {
  display: inline-block;
  font-size: 11px;
  margin-top: 7px;
  color: #8a8a8a;
}
body .container-fluid .wx-addAllChecked ul li:hover {
  background-color: #edebe8;
}
body .wx-search-container:first-child {
  margin-bottom: 15px;
}
.wx-search-row:last-child {
  border-right: none;
}
.active {
  display: block;
}
.deactive {
  display: none;
}
.disabled {
  display: none;
}
.mydisabled {
  background-color: #ececec !important;
}
.mydisabled::placeholder {
  color: #b8b7b7;
}
.fn-24 {
  font-size: 18px;
}
svg {
  cursor: pointer;
}
.wx-search .wx-showplace .inputbox {
  height: 23px;
  z-index: 999;
}
.wx-search .wx-showplace .inputbox span {
  color: grey;
  padding-left: 2px;
}

.wx-rent-input input,.wx-apartment-input input,.wx-rooms-inputs input,.field-inp input, .wx-price-inp input{
  cursor: pointer;
}